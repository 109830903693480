document.addEventListener("DOMContentLoaded", function () {
  // ==========================================================================
  // シゴト記事のフィルタリング
  // ==========================================================================
  const itemsPerPage = 10;
  let currentIndex = 0;
  let allPosts = Array.from(document.querySelectorAll(".p-work__card"));
  let filteredPosts = allPosts;

  function showPosts(posts) {
    posts.forEach((post, index) => {
      if (index < currentIndex + itemsPerPage) {
        post.classList.add("is-view");
        post.classList.remove("is-hide");
      } else {
        post.classList.remove("is-view");
        post.classList.add("is-hide");
      }
    });
  }

  function toggleLoadMoreButton() {
    const loadMoreButton = document.getElementById("load-more");
    if (filteredPosts.length > currentIndex + itemsPerPage) {
      loadMoreButton.style.display = "block";
    } else {
      loadMoreButton.style.display = "none";
    }
  }

  document.querySelectorAll(".p-work__form__button").forEach((button) => {
    button.addEventListener("click", function () {
      const filter = this.dataset.filter;
      currentIndex = 0;

      document.querySelectorAll(".p-work__form__button").forEach((btn) => {
        btn.classList.remove("is-current");
      });

      this.classList.add("is-current");

      if (filter === "all") {
        filteredPosts = allPosts;
      } else {
        filteredPosts = allPosts.filter((post) => {
          const categories = post.getAttribute("data-category").split(" ");
          return categories.includes(filter.slice(1));
        });
      }

      allPosts.forEach((post) => {
        post.classList.remove("is-view");
        post.classList.add("is-hide");
      });

      showPosts(filteredPosts);
      toggleLoadMoreButton();
    });
  });

  document.getElementById("load-more").addEventListener("click", function () {
    currentIndex += itemsPerPage;
    showPosts(filteredPosts);
    toggleLoadMoreButton();
  });

  showPosts(allPosts);
  toggleLoadMoreButton();
});
